import { Fragment, useEffect, useRef, useState } from "react";
import "./add-profession-post.css";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import ProfessionalPageApiService from "../../../data/api/services/professional-page/professional-page-api-service";
import { toast } from "react-toastify";
import FormFieldError from "../../../components/form-field-error/form-field-error";
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import PropertyApiService from "../../../data/api/services/property/property-api-service";
import ValidationError from "../../../common/validation-error";
import { Button, Card, Carousel, Col, Container, Modal, Row, Form } from "react-bootstrap";
import { FaChevronLeft, FaChevronRight, FaTrash } from "react-icons/fa";
import StorageService from "../../../data/storage/storage-service";
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SabPaisaApiService from "../../../data/api/services/sabpaisa/sabpaisa-api-service";
import ApiConfig from "../../../data/api/api-config";
import AuthApiService from "../../../data/api/services/auth/auth-api-service";
import RegisterApiService from "../../../data/api/services/register/register-api-service";
import TaxApiService from "../../../data/api/services/tax/tax-api-service";
import VerifiedIcon from '@mui/icons-material/Verified';
import Tooltip from '@mui/material/Tooltip';

const AddProfessionPost = (props: any) => {

    const professionalPageApiService = new ProfessionalPageApiService();
    const propertyApiService = new PropertyApiService();
    const authService: AuthApiService = new AuthApiService();
    const userId = StorageService.getUserId();
    const userType = StorageService.getUserType();
    const [userData, setUserData] = useState<any>([]);
    const apiConfig = new ApiConfig();
    const sabPaisaApiService = new SabPaisaApiService();
    const [sabpaisaUrl, setSabpaisaUrl] = useState("");
    const [encData, setEncData] = useState('');
    const [clientCode, setClientCode] = useState('');
    const [currentPageUrl, setCurrentPageUrl] = useState("");
    const properyCoverImageInput: any = useRef(null);
    const maxLength = 1000;
    const minLength = 0;
    const [locationSearchLoading, setLocationSearchLoading] = useState(false);
    const [locationData, setLocationData] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState<any>([]);
    const [callToActionData, setCallToActionData] = useState([]);
    const [callToActionIdStr, setCallToActionIdStr] = useState("");
    const [showCallToActionOption, setShowCallToActionOption] = useState(true);
    const [description, setDescription] = useState('');
    const [callToActionUrl, setCallToActionUrl] = useState('');
    const [postImage, setPostImage] = useState<any>([]);
    const [index, setIndex] = useState(0);
    const [locationValidationErrorMsg, setLocationValidationNameErrorMsg] = useState(null);
    const [callToActionValidationErrorMsg, setCallToActionValidationErrorMsg] = useState(null);
    const [professionalPagePostFormData, setProfessionalPagePostFormData] = useState<any>(new FormData());
    const [descriptionValidationErrorMsg, setDescriptionValidationErrorMsg] = useState(null);
    const [callToActionUrlValidationErrorMsg, setCallToActionUrlValidationErrorMsg] = useState(null);
    const [postImageValidationErrorMsg, setPostImageValidationErrorMsg] = useState(null);
    const [userPageData, setUserPageData] = useState<any>([]);
    const activePageItem = userPageData.find(item => item.activePage === true);
    const userPageId = activePageItem?.id;
    const [tagInput, setTagInput] = useState<string>('');
    const [tags, setTags] = useState<string[]>([]);
    const [hoveredTag, setHoveredTag] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);
    const [postTagsTemplateData, setPostTagsTemplateData] = useState([]);
    const [youtubeUrl, setYoutubeUrl] = useState('');
    const [unPurchasedTagData, setUnPurchasedTagData] = useState<any>([]);
    const [alreadyPurchasedTagData, setAlreadyPurchasedTagData] = useState<any>([]);
    const [selectedTagsToPurchase, setSelectedTagsToPurchase] = useState([]);
    const [selectAllTagsToPurchase, setSelectAllTagsToPurchase] = useState(false);
    const [showPurchaseTagDialog, setShowPurchaseTagDialog] = useState(false);
    const handleShowPurchaseTagDialog = () => setShowPurchaseTagDialog(true);
    const [checkedTagsToPurchase, setCheckedTagsToPurchase] = useState("");
    const [postId, setPostId] = useState('');
    const [showAlertDialog, setShowAlertDialog] = useState(false);
    const [showTagSearchBox, setShowTagSearchBox] = useState(false);
    const [tagSearchLoading, setTagSearchLoading] = useState(false);
    const [searchedTagData, setSearchedTagData] = useState([]);
    const typeaheadRef = useRef<any>(null);
    const [paymentPeriod, setPaymentPeriod] = useState('MONTHLY');
    const [showCodeAppliedText, setShowAppliedText] = useState(false);
    const [showApplyPromoCodePopUp, setShowApplyPromoCodePopUp] = useState(false);
    const [promoCodeValidationError, setPromoCodeValidationError] = useState(false);
    const [verifyCodeProcess, setVerifyCodeProcess] = useState(false);
    const [promoCode, setPromoCode] = useState<any>('');
    const [promoCodeText, setPromoCodeText] = useState("");
    const [coupenCode, setCoupenCode] = useState("");
    const registerService: RegisterApiService = new RegisterApiService();
    const taxApiService = new TaxApiService();
    const [taxPercentage, setTaxPercentage] = useState<any>([]);
    const [hover, setHover] = useState(false);

    const handlePromoCodeChange = (value: any) => {
        setPromoCodeText(value);
        setPromoCodeValidationError(false);
    };

    const validatePromoCode = () => {
        let isFormValid = true;

        if (!promoCodeText) {
            setPromoCodeValidationError(true);
            isFormValid = false;
        }
        return isFormValid;
    };

    const getPromoCodeByCode = async () => {
        try {
            if (validatePromoCode()) {
                setVerifyCodeProcess(true);
                let res = await registerService.getPromoCodeByCode(promoCodeText);
                setPromoCode(res);
                toast.success("PromoCode Verified!", { containerId: 'TR' });
                console.log('PromoCode', res);
            }
        } catch (err: any) {
            setVerifyCodeProcess(false);
            toast.error(err.response.data.message, { containerId: 'TR' });
        }
    };

    const applyPromoCode = () => {
        setShowApplyPromoCodePopUp(false);
        toast.success("PromoCode Applied Successfully!", { containerId: 'TR' });
        setCoupenCode(promoCodeText);
        setShowAppliedText(true);
    };

    const searchTagToPurchase = async (tagName: any) => {
        setTagSearchLoading(true);
        let data = await propertyApiService.searchTag(tagName);
        data = data.filter((tag: any) =>
            !unPurchasedTagData.some((selectedTag: any) => selectedTag.id === tag.id) &&
            !tags.includes(tag.tagName));
        console.log(tagName);
        setTagSearchLoading(false);
        setSearchedTagData(data);
    };

    const handleSearchedTagSelected = (item: any) => {
        if (item.length > 0) {
            const selectedTag = item[0];
            console.log(selectedTag);
            getTagPurchaseByTagId(selectedTag, 'SEARCH_TAG');
            setUnPurchasedTagData((prevArray) => {
                // const isTagAlreadyAdded = prevArray.some(tag => tag.id === selectedTag.id);
                // if (isTagAlreadyAdded) {
                //     return prevArray;
                // }
                const updatedArray = [...prevArray, selectedTag];
                setSelectAllTagsToPurchase(updatedArray.length === unPurchasedTagData.length);
                return updatedArray;
            });
            setSearchedTagData([]);
            typeaheadRef.current.clear();
        }
    };
      const handleDragStart = (e, index) => {
    e.dataTransfer.setData('draggedItem', index);
  };

  // Handle drop (reorder images)
  const handleDrop = (e, targetIndex) => {
    const draggedIndex = e.dataTransfer.getData('draggedItem');
    if (draggedIndex === targetIndex) return; // Ignore if the same item is dragged

    const updatedImages = [...postImage];
    const [draggedItem] = updatedImages.splice(draggedIndex, 1); // Remove the dragged item
    updatedImages.splice(targetIndex, 0, draggedItem); // Insert it at the new position

    setPostImage(updatedImages);
  };

  // Handle drag over
  const handleDragOver = (e) => {
    e.preventDefault();
  };

    const handleAlertYes = () => {
        window.location.reload();
    };

    const handleAlertNo = () => {
        setShowAlertDialog(false);
    };

    const handleClosePurchaseTagDialog = () => {
        setShowAlertDialog(true);
    }

    const handleCheckboxChange = (index, isChecked) => {
        setSelectedTagsToPurchase((prev) => {
            const updatedTags = isChecked
                ? [...prev, index]
                : prev.filter((i) => i !== index);
            setSelectAllTagsToPurchase(updatedTags.length === unPurchasedTagData.length);
            const tagNamesString = updatedTags
                .map(tagIndex => unPurchasedTagData[tagIndex]?.tagName)
                .join(", ");
            setCheckedTagsToPurchase(tagNamesString);
            return updatedTags;
        });
    };

    const handleSelectAllTagsToPurchaseChange = (e) => {
        const isChecked = e.target.checked;
        if (isChecked) {
            const allIndexes = unPurchasedTagData.map((_, index) => index);
            setSelectedTagsToPurchase(allIndexes);
            const tagNamesString = unPurchasedTagData
                .map(tag => tag?.tagName)
                .join(", ");
            console.log(tagNamesString, 'tagnames (select all)');
            setCheckedTagsToPurchase(tagNamesString);
        } else {
            setSelectedTagsToPurchase([]);
            setCheckedTagsToPurchase("");
        }
        setSelectAllTagsToPurchase(isChecked);
    };

    const totalCost = selectedTagsToPurchase.reduce((acc, curr) => {
        const tagItem = unPurchasedTagData[curr];
        const isPurchased = alreadyPurchasedTagData.some(data => data.id === tagItem.id);
        const tagCost = isPurchased
            ? 0
            : paymentPeriod === 'MONTHLY'
                ? parseFloat(tagItem.monthlyTagCost)
                : parseFloat(tagItem.yearlyTagCost) > 0
                    ? parseFloat(tagItem.yearlyTagCost)
                    : parseFloat(tagItem.monthlyTagCost) * 10;
        return acc + tagCost;
    }, 0);

    const taxRate = taxPercentage.taxPercentage / 100;

    const taxAmount = (totalCost * taxRate).toFixed(2);

    const grandTotal = (parseFloat(totalCost) + parseFloat(taxAmount)).toFixed(2);

    const handleCallToActionChange = (btId: any) => {
        setCallToActionIdStr(btId);
        setShowCallToActionOption(false);
    };

    const handleDescriptionOnChange = (ds: any) => {
        setDescription(ds);
    };

    const handleYoutubeURLOnChange = (value: any) => {
        setYoutubeUrl(value)
    };

    const handlecallToActionUrlOnChange = (cu: any) => {
        setCallToActionUrl(cu);
    };

    const onUploadPostListener = (event: any) => {
        let images: any = [];
        for (let i = 0; i < event.target.files.length; i++) {
            let image: any = {};
            image.file = event.target.files[i];
            image.url = URL.createObjectURL(image.file);
            images.push(image);
        }
        setPostImage(prevImages => [...prevImages, ...images]);
        setPostImageValidationErrorMsg('');
    }
    const moveImageUp = (index) => {
        if (index === 0) return; // Already at the top
        const updatedImages = [...postImage];
        const [movedImage] = updatedImages.splice(index, 1);
        updatedImages.splice(index - 1, 0, movedImage);
        setPostImage(updatedImages);
      };
    
      // Move image or video down
      const moveImageDown = (index) => {
        if (index === postImage.length - 1) return; // Already at the bottom
        const updatedImages = [...postImage];
        const [movedImage] = updatedImages.splice(index, 1);
        updatedImages.splice(index + 1, 0, movedImage);
        setPostImage(updatedImages);
      };
    const onProfessionalPageUploadPostListener = (event: any) => {
        const files = event.target.files;
        if (files.length > 0) {
            let mediaFiles: any = [];
            for (let i = 0; i < files.length; i++) {
                let media: any = {};
                media.file = files[i];
                if (media.file.type.startsWith('image/')) {
                    media.imagePath = URL.createObjectURL(media.file);
                    media.type = 'image';
                } else if (media.file.type.startsWith('video/')) {
                    media.videoPath = URL.createObjectURL(media.file);
                    media.type = 'video';
                }
                mediaFiles.push(media);
            }
            setPostImage(prevImages => [...prevImages, ...mediaFiles]);
            setPostImageValidationErrorMsg('');
        } else {
            setPostImage([]);
        }
    };

    const clearCoverImage = (index: number) => {
        const updatedImages = [...postImage];
        updatedImages.splice(index, 1);
        setPostImage(updatedImages);
    }

    const validateUploadPostFormData = (): void => {
        let errors: any[] = [];

        if (selectedLocation.length === 0) {
            let error: any = {};
            error.validationCode = "taggedLocationId";
            error.message = 'Location required';
            errors.push(error);
        }

        if (postImage.length <= 0) {
            let error: any = {};
            error.validationCode = "mediaUrl";
            error.message = 'Image required';
            errors.push(error);
        }

        if (errors.length > 0) {
            throw new ValidationError(errors);
        }
    }

    const showValidationErrorInlineUI = (error: ValidationError) => {
        let errors: any[] = error.errors;
        for (let i = 0; i < errors.length; i++) {
            let error: any = errors[i];
            let errorCode: any = error.validationCode;
            let errorMsg: any = error.message;

            if (errorCode === 'mediaUrl') {
                setPostImageValidationErrorMsg(errorMsg);
            }
            if (errorCode === 'taggedLocationId') {
                setLocationValidationNameErrorMsg(errorMsg);
            }
        }
    }

    const validateAndUploadPostFormData = () => {
        try {
            validateUploadPostFormData();
            if (professionalPagePostFormData.has("mediaUrl")) {
                professionalPagePostFormData.delete('mediaUrl');
            } for (let i = 0; i < postImage.length; i++) {
                professionalPagePostFormData.append('mediaUrl', postImage[i].file);
            }

            if (professionalPagePostFormData.has('callToAction')) {
                professionalPagePostFormData.delete('callToAction');
            }
            professionalPagePostFormData.append('callToAction', callToActionIdStr);

            if (professionalPagePostFormData.has("professionalPostId")) {
                professionalPagePostFormData.delete('professionalPostId');
            }
            professionalPagePostFormData.append('professionalPostId', userPageId);

            if (professionalPagePostFormData.has('taggedLocationId')) {
                professionalPagePostFormData.delete('taggedLocationId');
            }
            if (selectedLocation.length > 0) {
                professionalPagePostFormData.append('taggedLocationId', selectedLocation[0].id);
            }

            if (professionalPagePostFormData.has('caption')) {
                professionalPagePostFormData.delete('caption');
            }
            professionalPagePostFormData.append('caption', description);
            if (professionalPagePostFormData.has('callToActionUrl')) {
                professionalPagePostFormData.delete('callToActionUrl');
            }
            professionalPagePostFormData.append('callToActionUrl', callToActionUrl);

            if (professionalPagePostFormData.has("tags")) {
                professionalPagePostFormData.delete('tags');
            }
            professionalPagePostFormData.append('tags', concatenatedTags);
            if (professionalPagePostFormData.has('youtubeUrl')) {
                professionalPagePostFormData.delete('youtubeUrl');
            }
            professionalPagePostFormData.append('youtubeUrl', youtubeUrl);

        } catch (error: any) {
            throw error;
        }
    }

    const uploadProfessionalPagePost = async () => {
        try {
            validateAndUploadPostFormData();
            setLoading(true);
            let data = await professionalPageApiService.uploadProfessionalPagePost(professionalPagePostFormData);
            setPostId(data.id);
            if (data) {
                if (userType === "ROLE_PRO_USER" || !concatenatedTags) {
                    toast.success('Uploaded Post Successfully', { containerId: 'TR' });
                    window.location.reload();
                } else {
                    getTagByTagName(concatenatedTags);
                }
            }
        } catch (error) {
            showValidationErrorInlineUI(error);
        }
    };

    const searchLocation = async (q: any) => {
        setLocationSearchLoading(true);
        let data = await propertyApiService.searchLocation(q);
        console.log(q);
        setLocationSearchLoading(false);
        formatLocationData(data);
    };

    const formatLocationData = (data: any) => {
        formatLocationDataResponse(data);
        setLocationData(data);
    };

    const handleSelect = (selectedIndex) => {
        setIndex(selectedIndex);
    };

    const formatLocationDataResponse = (data: any) => {
        for (let i = 0; i < data.length; i++) {
            data[i].nameLabel = data[i].name + ' | ' + data[i].pinCode;
        }
    };

    const onLocationSelected = (e: any) => {
        if (e.length > 0) {
            console.log(e[0]);
            setSelectedLocation(e);
        }
        setLocationValidationNameErrorMsg('');
    };

    const clearSelectedLocation = () => {
        setSelectedLocation([]);
    };

    const getTemplateData = async () => {
        let data = await professionalPageApiService.getProfessionalPageTemplateData();
        console.log(data);
        setCallToActionData(data.callToActionData);
    };

    const getProfessionalPageDetails = async () => {
        try {
            console.log(userId);
            let res = await professionalPageApiService.getProfessionalPageData(userId);
            setUserPageData(res);
        } catch (error) {
            console.log(error);
        }
    };

    const getProfessionalPagePostTagTemplateData = async () => {
        let data = await professionalPageApiService.getProfessionalPagePostTagTemplateData();
        console.log(data);
        setPostTagsTemplateData(data);
    };

    const getTagByTagName = async (tagName) => {
        try {
            let res = await professionalPageApiService.getTagByTagName(tagName);
            console.log(res);
            let shouldReload = true;
            for (let tag of res) {
                if (tag.monthlyTagCost > 0) {
                    const conditionMet = await getTagPurchaseByTagId(tag, 'UPLOAD_POST');
                    if (conditionMet) {
                        shouldReload = false;
                    }
                }
            }
            if (shouldReload) {
                toast.success('Uploaded Post Successfully', { containerId: 'TR' });
                window.location.reload();
            }
        } catch (error) {
            console.log(error);
        }
    };

    const getTagPurchaseByTagId = async (tagData, accessFrom) => {
        try {
            let res = await professionalPageApiService.getTagPurchaseByTagId(tagData.id);
            console.log(res);
            if (accessFrom == 'UPLOAD_POST') {
                const matchingEntries = res.filter(entry => entry.personId === userId);
                if (matchingEntries.length <= 0) {
                    setLoading(false);
                    handleShowPurchaseTagDialog();
                    setUnPurchasedTagData(prevArray => [...prevArray, tagData]);
                    return true;
                } else {
                    setAlreadyPurchasedTagData(prevArray => [...prevArray, tagData]);
                }
                return false;
            } else if (accessFrom == 'SEARCH_TAG') {
                const matchingEntries = res.filter(entry => entry.personId === userId);
                if (matchingEntries.length > 0) {
                    setAlreadyPurchasedTagData(prevArray => [...prevArray, tagData]);
                }
            }
        } catch (error) {
            console.log(error);
        }
    };

    const getTaxResponse = async () => {
        try {
            let data = await taxApiService.getTax();
            setTaxPercentage(data);
            console.log(data, 'Tax');
        } catch (error) {
        }
    };

    useEffect(() => {
        const currentUrl = window.location.href;
        setCurrentPageUrl(currentUrl);
        console.log("Current URL:", currentUrl);
        setSabpaisaUrl(apiConfig.getSabpaisaURL);
        getUser();
        getProfessionalPageDetails();
        getTemplateData();
        getProfessionalPagePostTagTemplateData();
        getTaxResponse();
    }, [unPurchasedTagData]);

    const handleSubmit = () => {
        const form = document.getElementById('sabPaisaForm') as HTMLFormElement;
        if (form) {
            form.submit();
        }
    };

    useEffect(() => {
        if (shouldSubmit) {
            handleSubmit();
        }
    }, [encData, clientCode]);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTagInput(event.target.value);
    };

    const handleInputKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === ',' || event.key === 'Enter') {
            event.preventDefault();
            addTag();
        }
    };
    const renderMenuItemChildren = (option, props, index) => {

        return (
            <div key={index}>
                         <span style={{ color: 'black', marginRight: '5px' }}>{option.name}</span>
                <Tooltip title={option.isVerified ? 'Verified Location' : 'Location Not Verified'}>
                    <VerifiedIcon 
                        sx={{
                            color: option.isVerified ? '#0d6efd' : 'grey',  
                            fontSize: '15px', 
                        }} 
                    />
                </Tooltip>
{' | '}
                <span style={{ color: 'blue' }}>{option.pinCode}</span>
                {' | '}
                <span style={{ color: 'red' }}>{option.postOfficeName}</span>
                {' | '}
                <span style={{ color: 'green' }}>{option.talukaName}</span>
                {' | '}
                <span style={{ color: 'orange' }}>{option.districtName}</span>
                {' | '}
                <span style={{ color: '#fa0acc' }}>{option.abbreviation}</span>

            </div>
        );
    };
    const addTag = () => {
        if (tagInput.trim() !== '') {
            if (!tags.includes(tagInput.trim())) {
                setTags([...tags, tagInput.trim()]);
            }
            setTagInput('');
        }
    };

    const concatenatedTags = tags.join(',');

    const removeTag = (tagToRemove: string) => {
        setTags(tags.filter(tag => tag !== tagToRemove));
    };

    const [shouldSubmit, setShouldSubmit] = useState(false);

    const getUser = async () => {
        try {
            let res = await authService.getUser(userId);
            console.log('dataaaaaa=>', res);
            setUserData(res);
        } catch (error) {
            console.log(error);
        }
    };

    const handlePay = () => {
        getSabPaisaResponse();
    };

    const getSabPaisaResponse = async () => {
        try {
            console.log(currentPageUrl, 'Encoded Page URL');
            let url = `?payerName=${userData.firstName}&payerEmail=${userData.email}
            &payerMobile=${userData.mobile}&amount=${grandTotal}&accessPageFrom=${'MY_PROFILE_PAGE_ADD_POST'}
            &logInType=${userData.logInType}&tagName=${checkedTagsToPurchase}&postId=${postId}
            &pageId=${userPageId}&pageUrl=${currentPageUrl}&paymentPeriod=${paymentPeriod}`;
            console.log(currentPageUrl, 'gjhgjhsgcjhgcjhsjcjjsvcjvsvdch')
            let data = await sabPaisaApiService.getSabPaisaResponse(url);
            console.log(data);
            setEncData(data.encData);
            setClientCode(data.clientCode);
            setShouldSubmit(true);
        } catch (error) {
            if (error.response && error.response.data) {
                console.error('Error response data:', error.response.data);
            } else {
                console.error('Error fetching SabPaisa response:', error.message);
            }
        }
    };

    return <>
        <div style={{ position: 'relative' }}>
            {loading && (
                <div className="d-flex justify-content-center align-items-center"
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: 'rgba(255, 255, 255, 0.8)',
                        zIndex: 10
                    }}>
                    <div className="loading">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
            )}
            <div className="p-3">
                <div className="d-flex justify-content-between mb-1">
                    <div>
                        <button className="btn" onClick={props.handleClose} ><ArrowBackIcon /></button>
                        <span className="ms-3 fs-5 fw-bold">Add Post</span>
                    </div>
                    <div>
                        <button className="btn" onClick={props.handleClose} ><CloseIcon /></button>
                    </div>
                </div>
                {userType === "ROLE_PRO_USER" && (
                    <div className="row mt-3">
                        <div className="col-sm-6 mb-3">
                            <label className="mb-2">Call To Action</label>
                            <select className={callToActionValidationErrorMsg ? ' call-to-action error-field input-edit form-control' : 'call-to-action input-edit form-control'}
                                value={callToActionIdStr}
                                onChange={(e) => handleCallToActionChange(e.target.value)}>
                                {showCallToActionOption && <option>Select </option>}
                                {callToActionData && callToActionData.map((ctaData, index) => (<option className="text-edit" key={ctaData.id} value={ctaData.id}>{ctaData.label}</option>))}
                            </select>
                            <FormFieldError errorMessage={callToActionValidationErrorMsg}></FormFieldError>
                        </div>
                        <div className="col-sm-6 mb-3">
                            <label className="mb-2">Call To Action Url</label>
                            <input
                                type="text"
                                className={`call-to-action input-value form-control p-3 ${callToActionUrlValidationErrorMsg ? 'error-field input-edit' : ''}`}
                                placeholder="Call To Action Url"
                                value={callToActionUrl}
                                minLength={minLength}
                                maxLength={maxLength}
                                onChange={e => { handlecallToActionUrlOnChange(e.target.value) }}
                            />
                            <FormFieldError errorMessage={callToActionUrlValidationErrorMsg}></FormFieldError>
                        </div>
                    </div>
                )}
                <div className="row mt-3">
                    <Col className="mb-2">
                        <textarea
                            className={`input-value form-control p-3 ${descriptionValidationErrorMsg ? 'error-field input-edit' : ''}`}
                            placeholder="Add description"
                            value={description}
                            minLength={minLength}
                            maxLength={maxLength}
                            onChange={e => { handleDescriptionOnChange(e.target.value) }}
                            rows={3} cols={28}>
                        </textarea>
                        <FormFieldError errorMessage={descriptionValidationErrorMsg}></FormFieldError>
                        <div className='under-text m-0 mt-2'>
                            <div className="d-flex justify-content-between">
                                <span>Remaining:</span>
                                <span>{minLength + description.length}/1000</span>
                            </div>
                        </div>
                    </Col>
                </div>
                <div className="row mt-3">
                    <Col className="mb-2">
                        <label className="mb-2">Tag</label>
                        <div className="tag-container">
                            {tags.map(tag => (
                                <div key={tag} className="tag" style={hoveredTag === tag ? { border: '#ff5c5c solid 3px' } : {}}>
                                    <span className="tag-text">{tag}</span>
                                    <button className="tag-remove-btn px-1"
                                        onMouseEnter={() => setHoveredTag(tag)}
                                        onMouseLeave={() => setHoveredTag(null)}
                                        onClick={() => removeTag(tag)}>X</button>
                                </div>
                            ))}
                        </div>
                        <input
                            type="text"
                            className="form-control p-3"
                            value={tagInput}
                            onChange={handleInputChange}
                            onKeyPress={handleInputKeyPress}
                            list="ctaOptions"
                            placeholder="Add tags and press Enter"
                        />
                        <datalist id="ctaOptions" style={{ maxHeight: '20px' }}>
                            {postTagsTemplateData && postTagsTemplateData.map((tagData) => (
                                <option key={tagData.id} value={tagData.tagName} />
                            ))}
                        </datalist>
                        <button className="btn btn-primary mt-2 rounded-pill" onClick={addTag}>Add</button>
                    </Col>
                </div>
                <div className="row mt-3">
                    <Col className="mb-2">
                        <label className="mb-2">Tag Location<sup className="required">*</sup></label>
                        <div>
                            <Col className="justify-content-end align-items-center d-flex async-container">
                                <Col xs="auto" className="close-icon">
                                    {selectedLocation.length > 0 && (
                                        <FontAwesomeIcon icon={faTimes} onClick={clearSelectedLocation} />
                                    )}
                                </Col>
                                <Col>
                                    <AsyncTypeahead
                                        className={`test ${locationValidationErrorMsg ? 'error-field input-edit' : ''}`}
                                        labelKey="formatedLocationData"
                                        onSearch={(e) => searchLocation(e)}
                                        options={locationData}
                                        onChange={onLocationSelected}
                                        useCache={false}
                                        placeholder="Search Location"
                                        defaultSelected={selectedLocation}
                                        isLoading={locationSearchLoading}
                                        selected={selectedLocation}
                                        renderMenuItemChildren={renderMenuItemChildren}
                                    />
                                </Col>
                            </Col>
                            <FormFieldError errorMessage={locationValidationErrorMsg}></FormFieldError>
                        </div>
                        <div className='below-text mt-3 m-0'>
                            {selectedLocation.length > 0 && (
                                <button onClick={clearSelectedLocation} className="btn btn-danger mb-2 rounded-pill" type="button">
                                    Clear
                                </button>
                            )}
                        </div>
                    </Col>
                </div>
                <div className="row mt-3">
                    <Col md={6} className="mb-2 text-start">
                        <label className="mb-2">Youtube URL</label>
                        <textarea
                            className="input-value form-control"
                            placeholder="Enter Youtube URL/Embed"
                            value={youtubeUrl}
                            onChange={e => { handleYoutubeURLOnChange(e.target.value) }}
                            rows={2} cols={28}>
                        </textarea>
                    </Col>
                    <div className="col-sm-6 text-center mb-3">
                        <div className="mb-2">
                            <input
                                type="file"
                                className="visually-hidden"
                                ref={properyCoverImageInput}
                                onChange={onProfessionalPageUploadPostListener}
                                multiple
                                accept="image/,video/"
                                id="fileInput"
                            />
                            <label
                                htmlFor="fileInput"
                                className="img-box text-primary custom-file-input"
                                onClick={onUploadPostListener}
                            >
                                <AddAPhotoIcon /> <span className="ms-2"> Add Photos</span>
                            </label>
                            <input
                                type="button"
                                className="border rounded border-dark btn-height-size"
                                onClick={onUploadPostListener}
                            />
                            <FormFieldError errorMessage={postImageValidationErrorMsg}></FormFieldError>
                        </div>
                        <div>
                            <Carousel activeIndex={index}
                                onSelect={handleSelect}
                                interval={null}
                                indicators={postImage.length > 1}
                                controls={postImage.length > 1}
                                prevIcon={<FaChevronLeft style={{ color: '#0b7ec1', fontSize: '2em', background: 'aliceblue' }} />}
                                nextIcon={<FaChevronRight style={{ color: '#0b7ec1', fontSize: '2em', background: 'aliceblue' }} />}>
                                {postImage.length > 0 &&
                                    postImage.map((postImg, index) => (
                                        <Carousel.Item key={'post_image_' + index}>
                                            <Row className="">
                                                <div className="col-sm-12 d-flex align-items-center justify-content-center">
                                                    {postImg.type === 'image' ? (
                                                        <img src={postImg.imagePath} width={'100%'} height={'200px'} />
                                                    ) : (
                                                        <>
                                                            <video width={'100%'} height={'200px'}>
                                                                <source src={postImg.videoPath} type={postImg.file.type} />
                                                            </video>
                                                            <div className="d-flex align-items-center justify-content-center video-play-icon">
                                                                <PlayCircleOutlineIcon style={{ fontSize: 'xxx-large' }} />
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                                <div className="col-sm-12 d-flex justify-content-start">
                                                    <button
                                                        type="button"
                                                        className="btn btn-danger btn-sm rounded-pill mt-2"
                                                        aria-label="Close"
                                                        onClick={() => clearCoverImage(index)}
                                                    >
                                                        <span className="" aria-hidden="true">
                                                            Remove
                                                        </span>
                                                    </button>
                                                </div>
                                            </Row>
                                        </Carousel.Item>
                                    ))}
                            </Carousel>
                        </div>
                    </div>

                    
{/* <div className="col-sm-12 text-center">
      <div>
        <input
          type="file"
          className="visually-hidden"
          onChange={onProfessionalPageUploadPostListener}
          multiple
          accept="image/*,video/*"
          id="fileInput"
        />
        <label htmlFor="fileInput" className="img-box text-primary custom-file-input">
          <AddAPhotoIcon style={{ fontSize: '30px', transition: 'transform 0.3s ease' }} />
          
          <span className="ms-2" style={{ fontWeight: '500', fontSize: '16px' }}> Add Photos</span>
        </label>
        
        <FormFieldError errorMessage={postImageValidationErrorMsg}></FormFieldError>
      </div>
    
      {postImage.length > 0 && (
      
     
       
        <div
          className="mt-5"
          style={{
            padding: '20px',
            backgroundColor: '#f0f0f0', // Grey background for the card
            borderRadius: '8px',
            boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',         // Wrap the images into rows
              gap: '10px',              // Add space between the images
            }}
          >
            {postImage.map((postImg, index) => (
              <div
                key={'post_image_' + index}
                style={{
                  width: 'calc(33.33% - 10px)',  // Each image/video takes 1/3 of the row width
                  height: 'auto',
                  backgroundColor: postImg.type === 'image' ? '#d3d3d3' : '#b0c4de', // Grey for image, light steel blue for video
                  borderRadius: '8px',
                  padding: '10px',
                  position: 'relative',
                }}
                draggable
                onDragStart={(e) => handleDragStart(e, index)}
                onDrop={(e) => handleDrop(e, index)}
                onDragOver={handleDragOver}
              >
                <div className="col-sm-12 d-flex align-items-center justify-content-center">
                  {postImg.type === 'image' ? (
                    <img
                      src={postImg.imagePath}
                      alt="Uploaded"
                      width={'100%'}
                      height={'100px'}
                      style={{
                        borderRadius: '8px',
                        transition: 'transform 0.3s ease',
                      }}
                    />
                  ) : (
                    <>
                      <video
                        width={'100%'}
                        height={'100px'}
                        style={{
                          borderRadius: '8px',
                          transition: 'transform 0.3s ease',
                        }}
                      >
                        <source src={postImg.videoPath} type={postImg.file.type} />
                      </video>
                    <div className="d-flex align-items-center justify-content-center video-play-icon">
                        <PlayCircleOutlineIcon style={{ fontSize: '60px', color: '#fff', transition: 'transform 0.3s ease' }} />
                      </div>
                      
               
                    </>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div> */}














                    <div className="text-end p-3">
                        <button className="btn btn-success" onClick={uploadProfessionalPagePost} type="button">Upload Post</button>
                    </div>
                </div>
            </div>
        </div>
        <Modal show={showPurchaseTagDialog} centered backdrop="static" className="purchase-tag-dialog-modal"
            style={{ backgroundColor: 'rgba(255, 255, 255, 0.8)' }}>
            <Modal.Header className="border-0 justify-content-center position-relative">
                <Button variant="link" className="p-0 purchase-tag-back-icon" onClick={handleClosePurchaseTagDialog}>
                    <ArrowBackIcon />
                </Button>
                <Modal.Title className="text-center font-weight-bold text-uppercase">
                    Purchase Tag
                </Modal.Title>
                <Button variant="link" className="p-0 purchase-tag-close-icon" onClick={handleClosePurchaseTagDialog}>
                    <CloseIcon />
                </Button>
            </Modal.Header>
            <Modal.Body className="p-4 pb-0">
                <div className="d-flex justify-content-between mb-3 align-items-center">
                    {!showTagSearchBox ? (
                        <Button variant="primary" className="purchase-tag-add-tag-button" onClick={() => setShowTagSearchBox(true)}>
                            + Add Tag
                        </Button>
                    ) : (
                        <div className="d-flex align-items-center purchase-tag-search-box-container">
                            <AsyncTypeahead
                                className="tag-search-purchase-dialog"
                                id="tag-search"
                                ref={typeaheadRef}
                                labelKey="tagName"
                                onSearch={searchTagToPurchase}
                                options={searchedTagData}
                                onChange={handleSearchedTagSelected}
                                useCache={false}
                                placeholder="Search Tags"
                                isLoading={tagSearchLoading}
                            />
                            <Button variant="link" className="p-0 purchase-tag-close-search-icon" onClick={() => setShowTagSearchBox(false)}>
                                <CloseIcon />
                            </Button>
                        </div>
                    )}
                    <Form.Check
                        id="selectAllCheckbox"
                        type="checkbox"
                        label="Select All"
                        checked={selectAllTagsToPurchase}
                        onChange={handleSelectAllTagsToPurchaseChange}
                    />
                </div>
                <div className="switch-account-scrollbar" style={{ maxHeight: '65vh', overflow: 'auto' }}>
                    {unPurchasedTagData.map((tagItem, index) => (
                        <Card className="m-2 shadow-lg purchase-tag-card-modern" key={index}
                            onClick={() => handleCheckboxChange(index, !selectedTagsToPurchase.includes(index))}
                        >
                            <Card.Body className="d-flex justify-content-between align-items-center p-3">
                                <Form.Check type="checkbox" className="me-3 purchase-tag-custom-checkbox"
                                    checked={selectedTagsToPurchase.includes(index)}
                                    onChange={(e) => handleCheckboxChange(index, e.target.checked)}
                                    onClick={(e) => e.stopPropagation()} />
                                <div className="purchase-tag-info">
                                    <span className="purchase-tag-name d-block"
                                        style={{ wordBreak: 'break-word' }}>{tagItem.tagName}</span>
                                    <span
                                        className={`small ${alreadyPurchasedTagData.some(data => data.id === tagItem.id) ? 'text-warning' : tagItem.monthlyTagCost <= 0 ? 'text-success' : 'text-danger'}`}
                                        style={{ fontWeight: "bold" }}>
                                        {alreadyPurchasedTagData.some(data => data.id === tagItem.id) ? 'Purchased' : tagItem.monthlyTagCost <= 0 ? 'Free' : 'Premium'}
                                    </span>
                                </div>
                                <div className="purchase-tag-cost-final text-primary font-weight-bold"
                                    style={{ wordBreak: 'break-word' }}>
                                    {alreadyPurchasedTagData.some(data => data.id === tagItem.id) ? (
                                        <>
                                            <span className="crossed-out-cost">₹{paymentPeriod === 'MONTHLY'
                                                ? tagItem.monthlyTagCost
                                                : tagItem.yearlyTagCost > 0
                                                    ? tagItem.yearlyTagCost
                                                    : tagItem.monthlyTagCost * 10}
                                            </span>
                                            <span className="text-primary ms-2">₹0</span>
                                        </>
                                    ) : (
                                        `₹${paymentPeriod === 'MONTHLY'
                                            ? tagItem.monthlyTagCost
                                            : tagItem.yearlyTagCost > 0
                                                ? tagItem.yearlyTagCost
                                                : tagItem.monthlyTagCost * 10}`
                                    )}
                                </div>
                            </Card.Body>
                        </Card>
                    ))}
                </div>
                <div className="d-flex justify-content-center align-items-center mt-3 px-3">
                    {!showCodeAppliedText &&
                        <p>Have any PromoCode ? <a className="d-block d-sm-inline-block text-sm-left text-danger text-center"
                            onClick={() => setShowApplyPromoCodePopUp(true)}
                            style={{ cursor: 'pointer' }}> apply</a>
                        </p>
                    }
                    {showCodeAppliedText &&
                        <p className="green-text">Promo Code Applied !</p>
                    }
                </div>
                <div className="d-flex justify-content-end align-items-center px-3">
                    <Form.Check
                        id="selectMonthlyRadio"
                        type="radio"
                        label="Monthly"
                        name="paymentPeriod"
                        className="me-3"
                        checked={paymentPeriod === 'MONTHLY'}
                        onChange={() => setPaymentPeriod('MONTHLY')}
                    />
                    <Form.Check
                        id="selectYearlylyRadio"
                        type="radio"
                        label="Yearly"
                        name="paymentPeriod"
                        checked={paymentPeriod === 'YEARLY'}
                        onChange={() => setPaymentPeriod('YEARLY')}
                    />
                </div>
                <div className="d-flex flex-column mt-3 purchase-tag-total-section p-3">
                    <div className="d-flex justify-content-between align-items-center w-100">
                        <h4 className="fw-bold text-uppercase m-0 text-secondary">Total Amount:</h4>
                        <h4 className="purchase-tag-total-cost text-success fw-bold m-0">₹{totalCost.toFixed(2)}</h4>
                    </div>
                    <div className="d-flex justify-content-between align-items-center w-100">
                        <h4 className="fw-bold text-uppercase m-0 text-secondary">Tax ({taxPercentage.taxPercentage}%):</h4>
                        <h4 className="purchase-tag-tax-cost text-danger fw-bold m-0">₹{taxAmount}</h4>
                    </div>
                    <div className="d-flex justify-content-between align-items-center w-100">
                        <h5 className="fw-bold text-uppercase m-0">Grand Total:</h5>
                        <h5 className="purchase-tag-grand-total-cost text-primary fw-bold m-0">₹{grandTotal}</h5>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className="border-0 d-flex justify-content-between"
                style={{ flexWrap: 'nowrap' }}>
                <Button variant="secondary" className="w-50 py-3 purchase-tag-cancel-button"
                    onClick={handleClosePurchaseTagDialog}>
                    Cancel
                </Button>
                <Button variant="primary" className="w-50 py-3 purchase-tag-pay-button"
                    onClick={handlePay}
                    disabled={selectedTagsToPurchase.length === 0}>
                    Pay Now
                </Button>
            </Modal.Footer>
        </Modal>
        <Modal show={showAlertDialog} onHide={handleAlertNo} centered backdrop="static"
            style={{ backgroundColor: 'rgba(255, 255, 255, 0.8)' }}>
            <Modal.Header closeButton className="purchase-tag-alert-header">
                <Modal.Title className="purchase-tag-alert-title">Confirm Cancellation</Modal.Title>
            </Modal.Header>
            <Modal.Body className="purchase-tag-alert-body">
                Are you sure you want to cancel? The tag will not be added to your post.
            </Modal.Body>
            <Modal.Footer className="purchase-tag-alert-footer">
                <Button className="purchase-tag-alert-button-no" onClick={handleAlertNo}>
                    No
                </Button>
                <Button className="purchase-tag-alert-button-yes" onClick={handleAlertYes}>
                    Yes
                </Button>
            </Modal.Footer>
        </Modal>
        <Modal size="lg" show={showApplyPromoCodePopUp} onHide={() => setShowApplyPromoCodePopUp(false)} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>Verify Your Promo Code</Modal.Title>
            </Modal.Header>
            <div >
                <div className="modal-body">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="row">
                                <div className="col-sm-2 mt-2">
                                    <label>Promo Code :</label>
                                </div>
                                <div className="col-sm-4">
                                    <input type="text" className={`input-value form-control ${promoCodeValidationError ? 'is-invalid' : ''}`}
                                        value={promoCodeText} onChange={(e) => { handlePromoCodeChange(e.target.value) }}
                                        placeholder="Enter your code" name="firstName" />
                                    <i className="ti-user"></i>
                                    {promoCodeValidationError && <div className="invalid-feedback"> Promo code required</div>}
                                </div>
                                <div className="col-sm-3 mt-1">
                                    {!verifyCodeProcess && <button className="btn btn-success" type="button"
                                        onClick={getPromoCodeByCode}> verify</button>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {verifyCodeProcess && <div className="modal-body">
                <div className="row">
                    <div className="row">
                        <div className="col-sm-2 mt-2">
                            <label>You will get </label>
                        </div>
                        <div className="col-sm-3">
                            <label>{promoCode.discountAmount}</label>
                        </div>
                        <div className="col-sm-3 mt-1">
                            <button className="btn btn-success" type="button" onClick={applyPromoCode}>apply</button>
                        </div>
                    </div>
                </div>
            </div>}
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShowApplyPromoCodePopUp(false)}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
        <form
            id="sabPaisaForm"
            action={sabpaisaUrl}
            method="post"
            style={{ display: 'none' }}>
            <input type="text" name="encData" value={encData} />
            <input type="text" name="clientCode" value={clientCode} />
        </form>
    </>
}

export default AddProfessionPost;