import "./register-page.css";
import { useEffect, useRef, useState } from "react";
import RegisterApiService from "../../data/api/services/register/register-api-service";
import { SignupPayload, UpdateRegistrationPayload } from "../../data/api/services/register/signup-payload";
import { toast } from 'react-toastify';
import { Footer, Header } from "../../layouts";
import ProfessionalPageApiService from "../../data/api/services/professional-page/professional-page-api-service";
import Select from "react-select";
import AsyncTypeahead from "react-bootstrap-typeahead/types/components/AsyncTypeahead/AsyncTypeahead";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { faInfoCircle, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropertyApiService from "../../data/api/services/property/property-api-service";
import LocationApiService from "../../data/api/services/location/location-api-service";
import { SaveLocationPayload } from "../../data/api/services/location/location-payload";
import VerifiedIcon from '@mui/icons-material/Verified';
import Tooltip from '@mui/material/Tooltip';
import { AfterPaymentPayload, AuthPayload } from "../../data/api/services/auth/auth-payload";
import AuthApiService from "../../data/api/services/auth/auth-api-service";
import SmsApiService from "../../data/api/services/sms/sms-api-service";
import { useNavigate } from "react-router-dom";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import OTPPageLogo from '../../assets/otp-page-logo.png';
import avartar from '../../assets/avatar.png';

const RegisterRole = ({ registrationPayload, updatePayload, onNextCallback }) => {

    const registerService: RegisterApiService = new RegisterApiService();
    const tabNumber: number = 3;
    const propertyApiService = new PropertyApiService();
    const professionalPageApiservice = new ProfessionalPageApiService();
    const locationApiService = new LocationApiService();
    const [professionTypeData, setProfessionTypeData] = useState([]);
    const [selectedProfessionTypeIdStr, setselectedProfessionTypeIdStr] = useState<any>([]);
    const [isVisibleProfessionType, setIsVisibleProfessionType] = useState(false);
    const [professionTypeValidationErrorMsg, setProfessionTypeValidationErrorMsg] = useState(false);
    const [locationData, setLocationData] = useState<any>([]);
    const [locationSearchLoading, setLocationSearchLoading] = useState(false);
    const [selectedLocation, setSelectedLocation] = useState<any>([]);
    const [locationValidationError, setLocationValidationError] = useState(false);
    const [showCodeAppliedText, setShowAppliedText] = useState(false);
    const [showPopUp, setShowPopUp] = useState(false);
    const [promoCodeValidationError, setPromoCodeValidationError] = useState(false);
    const [verifyCodeProcess, setVerifyCodeProcess] = useState(false);
    const [promoCode, setPromoCode] = useState<any>('');
    const [promoCodeText, setPromoCodeText] = useState("");
    const [coupenCode, setCoupenCode] = useState("");
    const [locationName, setLocationName] = useState("");
    const [locationNameValidationError, setLocationNameValidationError] = useState(false);
    const [pinCodeSearchLoading, setPinCodeSearchLoading] = useState(false);
    const [pinCodeData, setPinCodeData] = useState<any>([]);
    const [selectedPinCode, setSelectedPinCode] = useState<any>([]);
    const [pinCodeValidationErrorMsg, setPinCodeValidationErrorMsg] = useState(false);
    const [showAddLocationDialog, setShowAddLocationDialog] = useState(false);
    const handleShowAddLocationDialog = () => setShowAddLocationDialog(true);
    const handleCloseAddLocationDialog = () => setShowAddLocationDialog(false);
    const [noResults, setNoResults] = useState(false);

    let navigate: any = useNavigate();
    const authService: AuthApiService = new AuthApiService();
    const smsService: SmsApiService = new SmsApiService();
    const [mobile, setMobile] = useState(registrationPayload?.mobile);
    const [mobileValidationError, setMobileNoValidationError] = useState(false);
    const [otpDigits, setOtpDigits] = useState(['', '', '', '']);
    const inputRefs = useRef([]);
    const [isOtpSent, setIsOtpSent] = useState(false);
    const [seconds, setSeconds] = useState(10);
    const [otp, setOtp] = useState('');
    const [showOTPDialog, setShowOTPDialog] = useState(false);
    const handleCloseOTPDialog = () => setShowOTPDialog(false);
    const handleShowOTPDialog = () => setShowOTPDialog(true);
    const [userPayload, setUserPayload] = useState<UpdateRegistrationPayload | null>(null);
    const imageSrc = registrationPayload.googleImage ? registrationPayload.googleImage : avartar;

    const handleMobileNumberChange = (value: any) => {
        const formattedValue = value.replace(/\D/g, '').slice(0, 10);
        setMobile(formattedValue);
        setMobileNoValidationError(false);
    };
    

    const getProfessionTypeData = async () => {
        let data = await professionalPageApiservice.getAllProfessionType();
        setProfessionTypeData(data);
    };

    const handleSelectedProfessionType = (spt: any) => {
        setselectedProfessionTypeIdStr(spt);
        setProfessionTypeValidationErrorMsg(false)
    };

    const validateUserType = () => {
        let isFormValid = true;
        if (selectedProfessionTypeIdStr.length === 0) {
            setProfessionTypeValidationErrorMsg(true);
            isFormValid = false;
        }
        if (!mobile) {
            setMobileNoValidationError(true);
            isFormValid = false;
        }
        if (mobile) {
            if (mobile.length <= 9) {
                toast.error("Enter a Valid Mobile No. ", { containerId: 'TR' });
                isFormValid = false;
            }
        }
        return isFormValid;
    }

    const executeSocialRegisterApi = async () => {
        try {
            let payload: UpdateRegistrationPayload = {
                id: registrationPayload.id,
                firstName: registrationPayload.firstName,
                lastName: "",
                email: registrationPayload.email,
                mobile: mobile,
                password: registrationPayload.password,
                userType: "ROLE_VIEW_USER",
                locationId: selectedLocation?.[0]?.id || "",
                membershipBenefitMapperId: "",
                amount: "",
                promoCode: coupenCode,
                logInType: registrationPayload.logInType,
                professionTypeId: selectedProfessionTypeIdStr.id
            };
            setUserPayload(payload);
            checkUser(payload);
        } catch (error) {
            console.log(error)
        }
    }

    const executeRegisterApi = async () => {
        try {
            if (validateUserType()) {
                if (registrationPayload.logInType == "NORMAL") {
                    submitContactDetailsFormData();
                    let payload: SignupPayload = {
                        firstName: registrationPayload.firstName,
                        lastName: registrationPayload.lastName,
                        email: registrationPayload.email,
                        mobile: registrationPayload.mobile,
                        password: registrationPayload.password,
                        userType: "ROLE_VIEW_USER",
                        locationId: selectedLocation?.[0]?.id || "",
                        otp: registrationPayload.otp,
                        membershipBenefitMapperId: "",
                        amount: "",
                        promoCode: coupenCode,
                        logInType: registrationPayload.logInType,
                        professionTypeId: selectedProfessionTypeIdStr.id
                    };
                    // updatePayload(payload);
                    // onNextCallback((tabNumber + 1));
                    let userData = await registerService.register(payload);
                    window.localStorage.setItem('number', JSON.stringify(payload));
                    getLogin();
                } else {
                    executeSocialRegisterApi();
                    // updatePayload(payload);
                    // onNextCallback((tabNumber - 1));
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    const getLogin = async () => {
        const numbers = JSON.parse(window.localStorage.getItem('number'));
        if (numbers.logInType == "NORMAL") {
            executeLoginApi();
        } else {
            executeSocialLogInApi();
        }
    }

    const executeLoginApi = async () => {
        const numbers = JSON.parse(window.localStorage.getItem('number'));
        try {
            let payload: AuthPayload = { mobile: numbers.mobile, password: numbers.password, roleCode: numbers.userType };
            await authService.doLogin(payload);
            toast.success('Successfully Registered', { containerId: 'TR' });
            navigate('/');
        } catch (error) {
            console.log("LoginPage Error");
            console.log(error);
        }
    };

    const executeSocialLogInApi = async () => {
        const numbers = JSON.parse(window.localStorage.getItem('number'));
        try {
            let payload: AfterPaymentPayload = {
                userName: numbers.email,
                logInType: numbers.logInType,
                roleCode: numbers.userType
            };
            await authService.afterPaymentLogIn(payload);
            toast.success('Successfully Registered', { containerId: 'TR' });
            navigate('/');
        } catch (error) {
            console.log(error);
        }
    }

    const submitContactDetailsFormData = async () => {
        try {
            let formData: FormData = new FormData();
            formData.append('firstName', registrationPayload.firstName)
            formData.append('address', "")
            formData.append('companyName', "")
            formData.append('latitude', "")
            formData.append('longitude', "")
            formData.append('lastName', registrationPayload.lastName)
            formData.append('whatsAppNumber', registrationPayload.mobile)
            formData.append('email', registrationPayload.email)
            formData.append('genderType', "")
            formData.append('professionIdStr', "")
            formData.append('ageIdStr', "")
            formData.append('locationIdStr', selectedLocation?.[0]?.id || "")
            formData.append('countryCode', "")
            formData.append('profileImageURL', "")
            formData.append('contactEntryTypeCode', "FROM_REGISTER")
            await registerService.saveContactDetails(formData);
        } catch (error: any) {
            console.error(error);
        }
    };

    const onLocationSelected = (e: any) => {
        if (e.length > 0) {
            console.log(e[0]);
            setSelectedLocation(e);
            // setLocationValidationError(e.length === 0);
        }
    };

    const clearSelectedLocation = () => {
        setSelectedLocation([]);
        setNoResults(false);
    };

    const searchLocation = async (q: any) => {
        setLocationSearchLoading(true);
        let data = await propertyApiService.searchLocation(q);
        console.log(q);
        setLocationSearchLoading(false);
        setLocationData(data);
        if (q.length > 3 && data.length === 0) {
            setNoResults(true);
        } else {
            setNoResults(false);
        }
    };

    const renderMenuItemChildren = (option, props, index) => {
        return (
            <div key={index}>
                <span style={{ color: 'black', marginRight: '5px' }}>{option.name}</span>
                <Tooltip title={option.isVerified ? 'Verified Location' : 'Location Not Verified'}>
                    <VerifiedIcon
                        sx={{
                            color: option.isVerified ? '#0d6efd' : 'grey',
                            fontSize: '15px',
                        }}
                    />
                </Tooltip>
                {' | '}
                <span style={{ color: 'blue' }}>{option.pinCode}</span>
                {' | '}
                <span style={{ color: 'red' }}>{option.postOfficeName}</span>
                {' | '}
                <span style={{ color: 'green' }}>{option.talukaName}</span>
                {' | '}
                <span style={{ color: 'orange' }}>{option.districtName}</span>
                {' | '}
                <span style={{ color: '#fa0acc' }}>{option.abbreviation}</span>
            </div>
        );
    };

    const searchPinCode = async (pinCode: any) => {
        setPinCodeSearchLoading(true);
        let data = await locationApiService.searchPinCode(pinCode);
        console.log(data);
        setPinCodeSearchLoading(false);
        setPinCodeData(data);
    };

    const onPinCodeSelected = (e: any) => {
        if (e.length > 0) {
            console.log(e[0]);
            setSelectedPinCode(e);
            setPinCodeValidationErrorMsg(e.length === 0);
        }
    };

    const clearSelectedPincode = () => {
        setSelectedPinCode([]);
    };

    const submitLocation = async () => {
        try {
            if (validateSaveLocation()) {
                let payload: SaveLocationPayload = {
                    name: locationName,
                    countryId: selectedPinCode[0].countryId,
                    stateId: selectedPinCode[0].stateId,
                    districtId: selectedPinCode[0].districtId,
                    talukaId: selectedPinCode[0].talukaId,
                    postOfficeId: selectedPinCode[0].postOfficeId,
                    pinCodeId: selectedPinCode[0].id,
                    locationPriorityId: '',
                    latitude: selectedPinCode[0].latitude,
                    longitude: selectedPinCode[0].longitude,
                    isVerified: false,
                    approvedBySuperAdmin: false
                };
                let data = await locationApiService.saveLocation(payload);
                console.log(data);
                const locationByIdData = await locationApiService.getLocationById(data.id);
                setSelectedLocation([locationByIdData]);
                setLocationData([locationByIdData]);
                handleCloseAddLocationDialog();
                setLocationName("");
                setSelectedPinCode([]);
                // setLocationValidationError(false);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const validateSaveLocation = () => {
        let isFormValid = true;
        if (!locationName) {
            setLocationNameValidationError(true);
            isFormValid = false;
        }
        if (selectedPinCode.length == 0) {
            setPinCodeValidationErrorMsg(true);
            isFormValid = false;
        }
        return isFormValid;
    }

    const renderMenuForPinCode = (option, props, index) => {
        return (
            <div key={index}>
                <span style={{ color: 'blue' }}>{option.pinCode}</span>
                {' | '}
                <span style={{ color: 'red' }}>{option.postOfficeName} (P.O.)</span>
                {' | '}
                <span style={{ color: 'green' }}>{option.postOfficeData.talukName}</span>
                {' | '}
                <span style={{ color: 'orange' }}>{option.postOfficeData.talukaData.districtName} (Dist)</span>
                {' | '}
                <span style={{ color: '#fa0acc' }}>{option.postOfficeData.talukaData.districtData.stateData.abbreviation}</span>
            </div>
        );
    };

    const openDialog = () => {
        setShowPopUp(true);
    };

    const handleClose = () => {
        setShowPopUp(false);
    };

    const handlePromoCodeChange = (value: any) => {
        setPromoCodeText(value);
        setPromoCodeValidationError(false);
    };

    const handleLocationNameChange = (value: any) => {
        setLocationName(value);
        setLocationNameValidationError(false);
    };

    const validatePromoCode = () => {
        let isFormValid = true;

        if (!promoCodeText) {
            setPromoCodeValidationError(true);
            isFormValid = false;
        }
        return isFormValid;
    };

    const getPromoCodeByCode = async () => {
        try {
            if (validatePromoCode()) {
                setVerifyCodeProcess(true);
                let res = await registerService.getPromoCodeByCode(promoCodeText);
                setPromoCode(res);
                toast.success("PromoCode Verified!", { containerId: 'TR' });
                console.log('PromoCode', res);
            }
        } catch (err: any) {
            setVerifyCodeProcess(false);
            toast.error(err.response.data.message, { containerId: 'TR' });
        }
    };

    const applyPromoCode = () => {
        setShowPopUp(false);
        toast.success("PromoCode Applied Successfully!", { containerId: 'TR' });
        setCoupenCode(promoCodeText);
        setShowAppliedText(true);
    };


    useEffect(() => {
        getProfessionTypeData();
    }, []);

    const checkUser = async (payload) => {
        try {
            let url = `?mobileNo=${mobile}&email=${payload.email}&logInType=${payload.logInType}`;
            let data = await authService.fetchUserByMobileNoEmailLogInTypeAndUserType(url);
            console.log('dataaaaaa=>', data);
            if (!data) {
                generateOtp();
            } else {
                toast.error("Mobile No. Already Exist ", { containerId: 'TR' });
            }
        } catch (error) {
            console.log(error);
        }
    };

    const generateOtp = async () => {
        try {
            let data = await smsService.generateOtp(mobile);
            setOtp(data.content);
            console.log(data.content);
            if (data.status == "Success") {
                handleShowOTPDialog();
            } else {
                toast.error("Try Again...", { containerId: 'TR' });
            }
        } catch (error) {
            console.log(error);
            toast.error(error.response.data.message, { containerId: 'TR' });
        }
    };

    const handleVerifyOtp = async () => {
        if (!userPayload) {
            toast.error("Something went wrong. Please try again.", { containerId: 'TR' });
            return;
        }
        const enteredOtp = otpDigits.join('');
        if (enteredOtp === otp) {
            registrationPayload.mobile = mobile;
            toast.success("OTP Verified Successfully", { containerId: 'TR' });
            handleCloseOTPDialog();
            let userData = await authService.updateSocialLogInUser(userPayload.id, userPayload);
            window.localStorage.setItem('number', JSON.stringify(userPayload));
            getLogin();
        } else {
            toast.error('Incorrect OTP. Please try again.', { containerId: 'TR' });
        }
    };

    const displayLastTwoDigits = (number) => {
        const length = number?.length;
        if (length < 2) {
            return number;
        }
        const firstTwoDigits = number?.substring(0, 2);
        const lastTwoDigits = number?.substring(number.length - 2);
        const maskedNumber = `${firstTwoDigits}******${lastTwoDigits}`;
        return maskedNumber;
    };

    const handleOtpChange = (index, value) => {
        if (value.length <= 1 && /^[0-9]*$/.test(value)) {
            const newOtpDigits = [...otpDigits];
            newOtpDigits[index] = value;
            setOtpDigits(newOtpDigits);
            if (index < otpDigits.length - 1) {
                inputRefs.current[index + 1].focus();
            }
        }
    };

    const resendOtp = async () => {
        try {
            let data = await smsService.generateOtp(mobile);
            setOtp(data.content);
            console.log(data.content);
        } catch (error) {
            console.log(error);
            toast.error(error.response.data.message, { containerId: 'TR' });
        }
    };

    const handleResendOtp = () => {
        setIsOtpSent(true);
        setSeconds(10);
        resendOtp();
    };

    return <>
        <div id="app">
            <Header></Header>
            <section>
            <div >
            <h5 className="text-center mt-3">
  <span className="welcome-text">Hi</span> 
  <span className="orange-text mx-2">{registrationPayload.firstName}</span>
  <span className="welcome-text">, Welcome to Veedu!</span>
</h5>
            <img src={imageSrc} alt="OTP Page Logo" className="d-block mx-auto circle-image mt-1" />
            <h6 className="text-center mt-2"><span className="continue-text">Glad to have you here!</span>  </h6>
            <h6 className="text-center"><span className="continue-text">Just fill in a few details to continue</span>  </h6>




    </div>                
                <div className="container">
                    <div className="row justify-content-center">
                        <Col md={6} className="mt-3">
                            <div id="sign-up" className="modal-content">
                                <div className="modal-body">
                                    <h5 className="text-center">Select Your Location and Profession</h5>
                                    <Row className="g-4">
                                        <Col lg={12}>
                                            <div>
                                                {noResults && selectedLocation.length === 0 && (
                                                    <div className="d-flex align-items-center mb-2" style={{ gap: '8px', color: '#0d6efd' }}>
                                                        <FontAwesomeIcon icon={faInfoCircle} />
                                                        <span>No matching location found. <a onClick={handleShowAddLocationDialog}
                                                            style={{ textDecoration: 'underline', cursor: 'pointer' }}>Add your location</a>
                                                        </span>
                                                    </div>
                                                )}
                                                <div className={`custom-input-field async-container d-flex justify-content-end align-items-center ${locationValidationError ? 'is-invalid' : ''}`}>
                                                    <Col className="close-icon align-items-center d-flex">
                                                        {selectedLocation.length > 0 && (
                                                            <FontAwesomeIcon icon={faTimes} onClick={clearSelectedLocation} />
                                                        )}
                                                    </Col>
                                                    <Col>
                                                        <AsyncTypeahead
                                                            className="rbt-input-wrapper"
                                                            id="basic-typeahead-single"
                                                            labelKey="formatedLocationData"
                                                            onSearch={(e) => searchLocation(e)}
                                                            options={locationData}
                                                            onChange={onLocationSelected}
                                                            useCache={false}
                                                            placeholder=" "
                                                            defaultSelected={selectedLocation}
                                                            isLoading={locationSearchLoading}
                                                            selected={selectedLocation}
                                                            renderMenuItemChildren={renderMenuItemChildren}
                                                            onInputChange={(input) => {
                                                                if (input.length == 0) {
                                                                    setNoResults(false);
                                                                }
                                                            }}
                                                        />
                                                        <span className="fw-bold custom-input-field-placeholder">Location</span>
                                                    </Col>
                                                </div>
                                                {locationValidationError && <div className="invalid-feedback px-2">Location required</div>}
                                            </div>
                                        </Col>
                                        <Col lg={12}>
                                            <div className={`custom-input-field ${professionTypeValidationErrorMsg ? 'is-invalid' : ''}`}>
                                                <select
                                                    className="form-control"
                                                    value={selectedProfessionTypeIdStr?.label || ""}
                                                    onChange={(e) => {
                                                        const selectedData = professionTypeData.find(ptData => ptData.label === e.target.value);
                                                        handleSelectedProfessionType(selectedData);
                                                    }}
                                                >
                                                    <option value="" disabled hidden>Select</option>
                                                    {professionTypeData.map((ptData, index) => (
                                                        <option key={ptData.id} value={ptData.label}>
                                                            {ptData.label}
                                                        </option>
                                                    ))}
                                                </select>
                                                <span className="fw-bold custom-input-field-placeholder">Profession Type</span>
                                            </div>
                                            {professionTypeValidationErrorMsg && (
                                                <div className="invalid-feedback">Profession Type required</div>
                                            )}
                                        </Col>
                                        <Col lg={12}>
                                            <div className={`custom-input-field ${mobileValidationError ? 'is-invalid' : ''}`}>
                                                <input type="number" placeholder=" "
                                                    value={mobile} onChange={e => { handleMobileNumberChange(e.target.value) }} />
                                                <span className="fw-bold custom-input-field-placeholder">Mobile No.</span>
                                            </div>
                                            {mobileValidationError && <div className="invalid-feedback px-2">Mobile No. required</div>}
                                        </Col>
                                    </Row>
                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group text-center mt-3">
                                            {!showCodeAppliedText && <p>Have any PromoCode ? <a className="link d-block d-sm-inline-block text-sm-left text-danger text-center" onClick={openDialog}> apply</a>
                                            </p>}
                                            {showCodeAppliedText && <p className="green-text">Promo Code Applied !</p>}
                                        </div>
                                    </div>
                                    <Row className="d-flex justify-content-center align-items-center mt-3">
                                        <div className="form-group col-lg-12">
                                            <button type="button" className="btn btn-md full-width btn-theme-light-2 rounded" onClick={executeRegisterApi}>
                                                Register
                                            </button>
                                        </div>
                                    </Row>

       
                                </div>
                            </div>
                        </Col>
                    </div>
                </div>
            </section>
            <Footer></Footer>
        </div>
        <Modal size="lg" show={showPopUp} onHide={handleClose} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>Verify Your Promo Code</Modal.Title>
            </Modal.Header>
            <div >
                <div className="modal-body">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="row">
                                <div className="col-sm-2 mt-2">
                                    <label>Promo Code :</label>
                                </div>
                                <div className="col-sm-4">
                                    <input type="text" className={`input-value form-control ${promoCodeValidationError ? 'is-invalid' : ''}`} value={promoCodeText} onChange={(e) => { handlePromoCodeChange(e.target.value) }}
                                        placeholder="Enter your code" name="firstName" />                                                 <i className="ti-user"></i>
                                    {promoCodeValidationError && <div className="invalid-feedback"> Promo code required</div>}
                                </div>
                                <div className="col-sm-3 mt-1">
                                    {!verifyCodeProcess && <button className="btn btn-success" type="button"
                                        onClick={getPromoCodeByCode}> verify</button>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {verifyCodeProcess && <div className="modal-body">
                <div className="row">
                    <div className="row">
                        <div className="col-sm-2 mt-2">
                            <label>You will get </label>
                        </div>
                        <div className="col-sm-3">
                            <label>{promoCode.discountAmount}</label>
                        </div>
                        <div className="col-sm-3 mt-1">
                            <button className="btn btn-success" type="button" onClick={applyPromoCode}>apply</button>
                        </div>
                    </div>
                </div>
            </div>}
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
        <Modal show={showAddLocationDialog} onHide={handleCloseAddLocationDialog} backdrop="static" size="lg">
            <Modal.Header className="fw-bold" closeButton>
                <Modal.Title>Add your location</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className="g-3">
                    <Col lg={12}>
                        <div className={`custom-input-field ${locationNameValidationError ? 'is-invalid' : ''}`}>
                            <input type="text"
                                placeholder=" "
                                value={locationName}
                                onChange={(e) => { handleLocationNameChange(e.target.value) }} />
                            <span className="fw-bold custom-input-field-placeholder">Enter your location</span>
                        </div>
                        {locationNameValidationError && <div className="invalid-feedback px-2 m-0">Location name required</div>}
                    </Col>
                    <Col lg={12}>
                        <div>
                            <div className={`custom-input-field async-container d-flex justify-content-end align-items-center ${pinCodeValidationErrorMsg ? 'is-invalid' : ''}`}>
                                <Col className="close-icon align-items-center d-flex">
                                    {selectedPinCode.length > 0 && (
                                        <FontAwesomeIcon icon={faTimes} onClick={clearSelectedPincode} />
                                    )}
                                </Col>
                                <Col>
                                    <AsyncTypeahead
                                        className="rbt-input-wrapper"
                                        id="select-pincode"
                                        labelKey="pinCode"
                                        onSearch={(e) => searchPinCode(e)}
                                        options={pinCodeData}
                                        onChange={onPinCodeSelected}
                                        useCache={false}
                                        placeholder=""
                                        defaultSelected={selectedPinCode}
                                        isLoading={pinCodeSearchLoading}
                                        selected={selectedPinCode}
                                        minLength={4}
                                        renderMenuItemChildren={renderMenuForPinCode}
                                    />
                                    <span className="fw-bold custom-input-field-placeholder">PinCode</span>
                                </Col>
                            </div>
                            {pinCodeValidationErrorMsg && <div className="invalid-feedback px-2">PinCode required</div>}
                        </div>
                    </Col>
                    <div className="d-flex flex-column flex-lg-row justify-content-end" style={{ gap: '10px' }}>
                        <button className="btn btn-danger" onClick={handleCloseAddLocationDialog}>Cancel</button>
                        <button className="btn btn-success" onClick={submitLocation}>Submit</button>
                    </div>
                </Row>
            </Modal.Body>
        </Modal>
        <Modal show={showOTPDialog} onHide={handleCloseOTPDialog} backdrop="static">
            <div className="d-flex justify-content-end">
                <button className="btn text-danger" onClick={handleCloseOTPDialog}> <HighlightOffIcon /></button>
            </div>
            <div className="d-flex justify-content-center align-items-center">
                <div className="card card-ui px-3">
                    <div className="text-center">
                        {/* <img src="./veeduapp-fevicon.png" width="50px" alt="" /> */}
                        <img src={OTPPageLogo} width="50px" alt="" />
                    </div>
                    <h5 className="m-0 text-center">Mobile phone verification</h5>
                    <span className="mobile-text">
                        Enter the code we just sent on your mobile phone{' '}
                        <b className="text-danger">{displayLastTwoDigits(mobile)}</b>
                    </span>
                    <div className="d-flex justify-content-center mt-5">
                        {otpDigits.map((digit, index) => (
                            <input
                                key={index}
                                type="text"
                                className="form-control text-input text-center"
                                value={digit}
                                maxLength={1}
                                onChange={(e) => handleOtpChange(index, e.target.value)}
                                ref={(input) => (inputRefs.current[index] = input)}
                            />
                        ))}
                    </div>
                    <div className="text-center my-3">
                        <span className="d-block mobile-text">
                            Not received an OTP?{' '}
                            <a
                                href="#"
                                className={`${isOtpSent ? 'text-muted' : 'text-danger'}`}
                                onClick={e => {
                                    e.preventDefault();
                                    if (!isOtpSent) handleResendOtp();
                                }}
                                style={{ pointerEvents: isOtpSent ? 'none' : 'auto', opacity: isOtpSent ? 0.5 : 1 }}
                            >
                                Resend OTP{isOtpSent && ` (${seconds}s)`}
                            </a>
                        </span>
                        <button
                            className="font-weight-bold rounded btn btn-danger cursor mt-2"
                            onClick={handleVerifyOtp}>
                            Submit
                        </button>
                    </div>
                </div>
            </div>
        </Modal>
    </>
}

export default RegisterRole;